import { Button, Center, CloseButton, Divider, Flex, LoadingOverlay, Text } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import { DeliveryApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IPermissions } from "../../interfaces/IPermissions";
import { message } from "../../utils/message";
import { FormLayout } from "../layout";

type Props = {
  action: any[];
  onCancel: () => void;
  reload: any;
  setChecked?: any;
};

export function SupplierSalesDeliveryForm({ action, onCancel, reload, setChecked }: Props) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const onSubmit = async () => {
    setLoading(true);
    try {
      await DeliveryApi.create([...action[1]?.map((c: any) => c.id)]);
      setChecked([]);
      setLoading(false);
      message.success("Таны хүсэлт амжилттай.");
      reload();
      onCancel && onCancel();
    } catch (error: any) {
      message.error(error.message || "Хүсэлт амжилтгүй.");
    }
    setLoading(false);
  };

  return (
    <FormLayout title="Хүргэлт үүсгэх" my={20} extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
      <LoadingOverlay visible={loading} />
      <Divider color="gray" my="xs" />
      <Center mt={10}>
        <Text fw={500} c="gray.7">
          Та хүргэлт үүсгэхдээ итгэлтэй байна уу?
        </Text>
      </Center>

      <Center mb={10}>
        <Text fw={500} c="gray.6">
          Хүргэлтийг үүсгэсний дараа "Хүргэлт удирдлага" меню руу орж хүргэх ажилтныг хуваарилаарай.
        </Text>
      </Center>
      <Divider color="gray" mt={20} />
      <Flex direction="row" justify="space-between" mt={20}>
        <Button variant="outline" onClick={() => onCancel && onCancel()} type="button">
          Болих
        </Button>
        {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_DN_ASSIGN").length > 0 && (
          <Button variant="filled" onClick={() => onSubmit()} type="submit">
            Зөвшөөрөх
          </Button>
        )}
      </Flex>
    </FormLayout>
  );
}

import { Table as BaseTable, Box, LoadingOverlay, Pagination, ScrollArea, Space, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import qs from "qs";
import React, { useImperativeHandle } from "react";
import useSwr from "swr";
import { IFilter } from "../../interfaces/IFilter";

export * from "./row-action";

type Props = {
  name: string;
  tbody: (data: any[]) => JSX.Element;
  thead: JSX.Element;
  filters?: { [key: string]: string | number };
  pagination?: boolean;
  dataSource?: any[];
  loadData?: (filter?: IFilter) => Promise<any>;
  limit?: number;
  onResult?: any;
  colCount?: number;
};

export type ITableRef = { reload: () => void };

function CustomTable({ name, filters = {}, pagination = true, loadData, limit = 20, onResult, thead, tbody, colCount }: Props, ref: React.Ref<ITableRef>) {
  const { classes } = useStyles();
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const tableRef = React.useRef<HTMLTableElement>(null);
  const [offset, setOffset] = React.useState({ page: 1, limit: limit || 20 });
  const [fallbackData, setFallbackData] = React.useState<{
    count: number;
    rows: any[];
  }>({ count: 0, rows: [] });

  const { data, mutate, isLoading } = useSwr(
    `table.${name}.[${offset.page}, ${offset.limit}]?${qs.stringify(filters)}`,
    async () => {
      if (loadData) {
        let res = await loadData({ offset: offset, filter: filters } as IFilter);
        setFallbackData(res);
        return res;
      }
      return { count: 0, rows: [] };
    },
    {
      fallbackData: fallbackData,
      onSuccess: (data) => {
        onResult && onResult(data);
        if (scrollRef.current) {
          scrollRef.current.scrollTo({ top: 0 });
        }
      },
    },
  );

  useImperativeHandle(ref, () => ({
    reload() {
      return mutate();
    },
  }));

  return (
    <div className={classes.container}>
      <ScrollArea h="100%">
        <BaseTable ref={tableRef} horizontalSpacing="md" verticalSpacing="xs" highlightOnHover striped="even">
          {thead}
          {data?.rows?.length > 0 ? (
            tbody(data.rows)
          ) : (
            <BaseTable.Tbody>
              <BaseTable.Tr>
                <BaseTable.Td colSpan={colCount ? colCount : 0}>
                  <Box
                    sx={(theme) => ({
                      padding: theme.spacing.xl,
                    })}>
                    <Text fw={500} ta="center" w="100%">
                      Өгөгдөл олдсонгүй.
                    </Text>
                  </Box>
                </BaseTable.Td>
              </BaseTable.Tr>
            </BaseTable.Tbody>
          )}
        </BaseTable>
        <Space h="md" />
        <LoadingOverlay visible={isLoading} />
      </ScrollArea>

      {pagination && (
        <div className={classes.pagination}>
          <Pagination
            onChange={(page) => setOffset((state) => ({ ...state, page: page }))}
            total={data.count / offset.limit + (data.count % offset.limit > 0 ? 1 : 0)}
          />
        </div>
      )}
    </div>
  );
}

const CustomTableComponent = React.forwardRef(CustomTable);

export { CustomTableComponent as CustomTable };

const useStyles = createStyles(() => ({
  container: {
    position: "relative",
  },
  pagination: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: "15px 0",
  },
}));

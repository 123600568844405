import { ActionIcon, Badge, Box, Button, Collapse, Flex, Grid, Group, Input, Text, UnstyledButton } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconMap2, IconSearch, IconSquareArrowDown, IconSquareArrowUp, IconX } from "@tabler/icons-react";
import dayjs from "dayjs";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import useSWR from "swr";
import * as yup from "yup";
import { NetworkApi, OrderApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { INetworkSelect } from "../../interfaces/INetworkSelect";
import { IPermissions } from "../../interfaces/IPermissions";
import { dateFormat, dateTimeSecFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";
import { Form } from "../form";
import { DatePickerField } from "../form/datepicker-field";
import { RadioGroupField } from "../form/radio-group-field";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { PageLayout } from "../layout";
import { SupplierCustomerDynamicForm } from "./customer-dynamic-form";

const schema = yup.object({
  // regNumber: yup.string().matches(regNumber, "Татвар төлөгчийн дугаарын формат аа шалгана уу.").required("Заавал бөглөнө!").nullable(),
  regNumber: yup.string().when(["partnerType"], (type) => {
    if (typeof type === "string" && type === "CITIZEN") {
      return yup
        .string()
        .required("Заавал бөглөнө!")
        .matches(/^[А-Яа-я|Үү|Өө|Ёё]{2}[0-9]{8}$/, "Формат буруу байна!")
        .min(10, "Регистрийн дугаар буруу байна!");
    } else if (typeof type === "string" && type === "COMPANY") {
      return yup
        .string()
        .required("Заавал бөглөнө!")
        .matches(/^[0-9]{7}$/, "Формат буруу байна!")
        .min(7, "Дугаар 7 оронтой байна!")
        .max(7, "Дугаар 7 оронтой байна!");
    }
    return yup.string().nullable();
  }),
  partnerName: yup.string().required("Заавал бөглөнө!").nullable(),
  supplierId: yup.string().required("Заавал бөглөнө!").nullable(),
  deliveryType: yup.string().required("Заавал сонгох!").nullable(),
  deliveryDate: yup.string().when(["deliveryType"], (deliveryType) => {
    if (typeof deliveryType === "string" && deliveryType === "CUSTOM_DATE") {
      return yup.string().required("Заавал бөглөнө!").nullable();
    }
    return yup.string().nullable();
  }),
  deliveryCheck: yup.string().when(["deliveryType"], (deliveryType) => {
    if (typeof deliveryType === "string" && deliveryType === "DEFAULT_DATE") {
      return yup.string().required("Заавал бөглөнө!").nullable();
    }
    return yup.string().nullable();
  }),
  lines: yup.array().of(
    yup.object({
      quantity: yup.string().required("Заавал бөглөнө!").nullable(),
    }),
  ),
  additionalLines: yup.array().of(
    yup.object({
      name: yup.string().required("Заавал бөглөнө!").nullable().max(255, "Хэтэрхий урт байна!"),
      unit: yup.string().required("Заавал бөглөнө!").nullable().max(100, "Хэтэрхий урт байна!"),
      price: yup.number().min(1, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
      quantity: yup.number().min(1, "Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
      discountType: yup.string().required("Заавал бөглөнө!").nullable(),
      discountValue: yup.number().min(1, "Заавал бөглөнө!").required("Заавал бөглөнө!").typeError(""),
    }),
  ),
  attachments: yup.array().of(
    yup.object({
      name: yup.string().required("Заавал бөглөнө!").nullable().max(255, "Хэтэрхий урт байна!"),
      url: yup.string().required("Заавал бөглөнө!").nullable().max(500, "Хэтэрхий урт байна!"),
      description: yup.string().required("Заавал бөглөнө!").nullable().max(255, "Хэтэрхий урт байна!"),
    }),
  ),
});

export type IFormData = {
  regNumber: string;
  businessName: string;
  businessId: string;
  receiverBranchId: string;
  deliveryType: string;
  deliveryDate: string;
  receiverStaffId: string;
  lines: [];
  additionalLines: [];
  senderNote: string;
  senderAdditionalNote: string;
  shippingAmount: string;
  discountType: string;
  discountValue: string;
  supplierId: string;
  attachments: [];
  toReview: boolean;
  send: boolean;
  types: boolean;
  deliveryCheck: string;
  partnerType: string;
};

type IProps = {
  action: any[];
};

const initData = {
  regNumber: undefined,
  businessName: undefined,
  businessId: undefined,
  receiverBranchId: undefined,
  deliveryType: undefined,
  deliveryDate: undefined,
  receiverStaffId: undefined,
  lines: [],
  additionalLines: [],
  senderNote: undefined,
  senderAdditionalNote: undefined,
  shippingAmount: undefined,
  discountType: undefined,
  discountValue: undefined,
  attachments: [],
  toReview: false,
  send: false,
  supplierId: undefined,
  types: false,
  deliveryCheck: undefined,
  partnerType: undefined,
};

export function SupplierCustomerForm({ action }: IProps) {
  const breadcrumbs = useBreadcrumb();
  const { classes, cx } = useStyles();
  const nowMoment = new Date();
  const ref = useRef<any>(null);
  const navigate = useNavigate();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [collapseOpen, setCollapseOpen] = React.useState(true);
  const [collapsedOpen, setCollapsedOpen] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const { deliveryDateTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [businessData, setBusinessData] = React.useState<any>("");
  const [active, setActive] = React.useState("");

  const [data] = React.useState<IFormData>({
    deliveryDate: "",
    deliveryType: "DEFAULT_DATE",
    regNumber: "",
    businessName: "",
    lines: [],
    attachments: [],
    additionalLines: [],
    senderNote: "",
    senderAdditionalNote: "",
    discountType: "",
    discountValue: "",
    shippingAmount: "",
    supplierId: "",
    deliveryCheck: "",
    partnerType: "",
    ...(action && action[0] === "new"
      ? {
          ...action[1],
          partnerName: action && action[1]?.profileName,
          regNumber: action && action[1]?.regNumber,
          supplierId: action && action[1]?.id,
          types: false,
          partnerType: action && action[1]?.partner?.type,
        }
      : {}),
  });

  const onSubmit = async (value: any, type: string) => {
    setLoading(true);
    if (value.types && !(businessData || []).supplierStaff) {
      message.error("Нийлүүлэгчийн менежерийг тохируулна уу!");
    } else if (!value.types && !(action[1] || []).supplierStaff) {
      message.error("Нийлүүлэгчийн менежерийг тохируулна уу!");
    } else if (value.types && !(businessData || []).buyerStaff) {
      message.error("Худалдан авагчийн менежерийг тохируулна уу!");
    } else if (!value.types && !(action[1] || []).buyerStaff) {
      message.error("Худалдан авагчийн менежерийг тохируулна уу!");
    } else if (!value?.lines) {
      message.error("Бараа бүтээгдэхүүн, ажил үйлчилгээ нэмнэ үү!");
    } else if (value.types && !(businessData || []).receiverStaff) {
      message.error("Харилцагч хүлээн авах ажилтан бүртгээгүй байна!");
    } else if (!value.types && !action[1]?.receiverStaff?.id) {
      message.error("Харилцагч хүлээн авах ажилтан бүртгээгүй байна!");
    } else if (value?.types && businessData?.receiverBranchId === undefined) {
      message.error("Худалдан авагч хүлээн авах салбараа тохируулаагүй байна!");
    } else if (!value?.types && value?.receiverBranchId === undefined) {
      message.error("Худалдан авагч хүлээн авах салбараа тохируулаагүй байна!");
    } else {
      try {
        switch (type) {
          case "save": {
            if (value?.types) {
              await OrderApi.create({
                businessId: businessData?.id,
                receiverBranchId: businessData?.receiverBranch?.id,
                deliveryType: value?.deliveryType,
                deliveryDate: value?.deliveryType === "DEFAULT_DATE" ? dayjs(value?.deliveryCheck).format() : dayjs(value?.deliveryDate).format(),
                receiverStaffId: businessData?.receiverStaff?.id,
                senderNote: value?.senderNote,
                senderAdditionalNote: value?.senderAdditionalNote,
                shippingAmount: value?.shippingAmount,
                discountType: value?.discountType,
                discountValue: value?.discountValue,
                toReview: false,
                send: false,
                attachments: (value?.attachments || [])?.map((item: any) => {
                  return {
                    description: item?.description,
                    name: item?.name,
                    url: item?.url,
                  };
                }),
                lines: (value?.lines || [])?.map((item: any) => {
                  return {
                    variantId: item?.id,
                    discountType: item?.discountType,
                    discountValue: item?.discountValue,
                    quantity: item?.quantity,
                  };
                }),
                additionalLines: (value?.additionalLines || [])?.map((item: any) => {
                  return {
                    name: item?.name,
                    unit: item?.unit,
                    price: item?.price,
                    quantity: item?.quantity,
                    discountType: item?.discountType,
                    discountValue: item?.discountValue,
                  };
                }),
              });
            } else {
              await OrderApi.create({
                businessId: action && action[1]?.id,
                receiverBranchId: action && action[1]?.receiverBranch?.id,
                receiverStaffId: action && action[1]?.receiverStaff?.id,
                deliveryType: value?.deliveryType,
                deliveryDate: value?.deliveryType === "DEFAULT_DATE" ? dayjs(value?.deliveryCheck).format() : dayjs(value?.deliveryDate).format(),
                senderNote: value?.senderNote,
                senderAdditionalNote: value?.senderAdditionalNote,
                shippingAmount: value?.shippingAmount,
                discountType: value?.discountType,
                discountValue: value?.discountValue,
                toReview: false,
                send: false,
                attachments: (value?.attachments || [])?.map((item: any) => {
                  return {
                    description: item?.description,
                    name: item?.name,
                    url: item?.url,
                  };
                }),
                lines: (value?.lines || [])?.map((item: any) => {
                  return {
                    variantId: item?.id,
                    discountType: item?.discountType,
                    discountValue: item?.discountValue,
                    quantity: item?.quantity,
                  };
                }),
                additionalLines: (value?.additionalLines || [])?.map((item: any) => {
                  return {
                    name: item?.name,
                    unit: item?.unit,
                    price: item?.price,
                    quantity: item?.quantity,
                    discountType: item?.discountType,
                    discountValue: item?.discountValue,
                  };
                }),
              });
            }
            message.success("Таны хүсэлт амжилттай.");
            break;
          }
          case "review": {
            if (value?.types) {
              await OrderApi.create({
                businessId: businessData?.id,
                receiverBranchId: businessData?.receiverBranch?.id,
                deliveryType: value?.deliveryType,
                deliveryDate: value?.deliveryType === "DEFAULT_DATE" ? dayjs(value?.deliveryCheck).format() : dayjs(value?.deliveryDate).format(),
                receiverStaffId: businessData?.receiverStaff?.id,
                senderNote: value?.senderNote,
                senderAdditionalNote: value?.senderAdditionalNote,
                shippingAmount: value?.shippingAmount,
                discountType: value?.discountType,
                discountValue: value?.discountValue,
                toReview: true,
                send: false,
                attachments: (value?.attachments || [])?.map((item: any) => {
                  return {
                    description: item?.description,
                    name: item?.name,
                    url: item?.url,
                  };
                }),
                lines: (value?.lines || [])?.map((item: any) => {
                  return {
                    variantId: item?.id,
                    discountType: item?.discountType,
                    discountValue: item?.discountValue,
                    quantity: item?.quantity,
                  };
                }),
                additionalLines: (value?.additionalLines || [])?.map((item: any) => {
                  return {
                    name: item?.name,
                    unit: item?.unit,
                    price: item?.price,
                    quantity: item?.quantity,
                    discountType: item?.discountType,
                    discountValue: item?.discountValue,
                  };
                }),
              });
            } else {
              await OrderApi.create({
                businessId: action && action[1]?.id,
                receiverBranchId: action && action[1]?.receiverBranch?.id,
                receiverStaffId: action && action[1]?.receiverStaff?.id,
                deliveryType: value?.deliveryType,
                deliveryDate: value?.deliveryType === "DEFAULT_DATE" ? dayjs(value?.deliveryCheck).format() : dayjs(value?.deliveryDate).format(),
                senderNote: value?.senderNote,
                senderAdditionalNote: value?.senderAdditionalNote,
                shippingAmount: value?.shippingAmount,
                discountType: value?.discountType,
                discountValue: value?.discountValue,
                toReview: true,
                send: false,
                attachments: (value?.attachments || [])?.map((item: any) => {
                  return {
                    description: item?.description,
                    name: item?.name,
                    url: item?.url,
                  };
                }),
                lines: (value?.lines || [])?.map((item: any) => {
                  return {
                    variantId: item?.id,
                    discountType: item?.discountType,
                    discountValue: item?.discountValue,
                    quantity: item?.quantity,
                  };
                }),
                additionalLines: (value?.additionalLines || [])?.map((item: any) => {
                  return {
                    name: item?.name,
                    unit: item?.unit,
                    price: item?.price,
                    quantity: item?.quantity,
                    discountType: item?.discountType,
                    discountValue: item?.discountValue,
                  };
                }),
              });
            }
            message.success("Амжилттай хүсэлтийг хяналтад илгээсэн.");
            break;
          }
          case "send": {
            if (value?.types) {
              await OrderApi.create({
                businessId: businessData?.id,
                receiverBranchId: businessData?.receiverBranch?.id,
                deliveryType: value?.deliveryType,
                deliveryDate: value?.deliveryType === "DEFAULT_DATE" ? dayjs(value?.deliveryCheck).format() : dayjs(value?.deliveryDate).format(),
                receiverStaffId: businessData?.receiverStaff?.id,
                senderNote: value?.senderNote,
                senderAdditionalNote: value?.senderAdditionalNote,
                shippingAmount: value?.shippingAmount,
                discountType: value?.discountType,
                discountValue: value?.discountValue,
                toReview: false,
                send: true,
                attachments: (value?.attachments || [])?.map((item: any) => {
                  return {
                    description: item?.description,
                    name: item?.name,
                    url: item?.url,
                  };
                }),
                lines: (value?.lines || [])?.map((item: any) => {
                  return {
                    variantId: item?.id,
                    discountType: item?.discountType,
                    discountValue: item?.discountValue,
                    quantity: item?.quantity,
                  };
                }),
                additionalLines: (value?.additionalLines || [])?.map((item: any) => {
                  return {
                    name: item?.name,
                    unit: item?.unit,
                    price: item?.price,
                    quantity: item?.quantity,
                    discountType: item?.discountType,
                    discountValue: item?.discountValue,
                  };
                }),
              });
            } else {
              await OrderApi.create({
                businessId: action && action[1]?.id,
                receiverBranchId: action && action[1]?.receiverBranch?.id,
                receiverStaffId: action && action[1]?.receiverStaff?.id,
                deliveryType: value?.deliveryType,
                deliveryDate: value?.deliveryType === "DEFAULT_DATE" ? dayjs(value?.deliveryCheck).format() : dayjs(value?.deliveryDate).format(),
                senderNote: value?.senderNote,
                senderAdditionalNote: value?.senderAdditionalNote,
                shippingAmount: value?.shippingAmount,
                discountType: value?.discountType,
                discountValue: value?.discountValue,
                toReview: false,
                send: true,
                attachments: (value?.attachments || [])?.map((item: any) => {
                  return {
                    description: item?.description,
                    name: item?.name,
                    url: item?.url,
                  };
                }),
                lines: (value?.lines || [])?.map((item: any) => {
                  return {
                    variantId: item?.id,
                    discountType: item?.discountType,
                    discountValue: item?.discountValue,
                    quantity: item?.quantity,
                  };
                }),
                additionalLines: (value?.additionalLines || [])?.map((item: any) => {
                  return {
                    name: item?.name,
                    unit: item?.unit,
                    price: item?.price,
                    quantity: item?.quantity,
                    discountType: item?.discountType,
                    discountValue: item?.discountValue,
                  };
                }),
              });
            }
            message.success("Хүсэлтийг амжилттай илгээсэн.");
            break;
          }
        }
        navigate("/supplier-customer");
      } catch (err) {
        message.error((err as HttpHandler)?.message!);
      }
    }
    setLoading(false);
  };

  const { data: networkData } = useSWR(`/ord/network/select/partner-data`, async () => {
    if ((user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_NET").length > 0) {
      const res = await NetworkApi.select({ regNumber: "", partnerName: "", limit: 20 });
      const mapedRes = res.reduce((acc: any, it: any) => {
        acc[it.id] = it;
        return acc;
      }, {});
      return {
        res,
        mapedRes,
      };
    }
  });

  const businessOnChange = async (id: any, setFieldValue: any) => {
    if (id) {
      try {
        const res = await NetworkApi.get(id);
        setBusinessData(res);
        setFieldValue("types", true);
        setFieldValue("lines", undefined);
      } catch (error) {
        message.error((error as HttpHandler)?.message!);
      }
    }
  };

  const dates = new Date();
  const ts = +dates;
  const monday1 = ts - (ts % (60 * 60 * 24 * (7 - 4) * 1000)) + 176400000;
  const monday2 = ts - (ts % (60 * 60 * 24 * (7 - 4) * 1000)) + 262800000;
  const monday3 = ts - (ts % (60 * 60 * 24 * (7 - 4) * 1000)) + 349200000;
  const monday4 = ts - (ts % (60 * 60 * 24 * (7 - 4) * 1000)) + 435600000;
  const monday5 = ts - (ts % (60 * 60 * 24 * (7 - 4) * 1000)) + 522000000;
  const monday6 = ts - (ts % (60 * 60 * 24 * (7 - 4) * 1000)) + 608400000;
  const day1 = new Date(monday1);
  const day2 = new Date(monday2);
  const day3 = new Date(monday3);
  const day4 = new Date(monday4);
  const day5 = new Date(monday5);
  const day6 = new Date(monday6);

  const staticDay = [
    {
      id: "1",
      date: day1,
      weekend: day1.getDay(),
    },
    {
      id: "2",
      date: day2,
      weekend: day2.getDay(),
    },
    {
      id: "3",
      date: day3,
      weekend: day3.getDay(),
    },
    {
      id: "4",
      date: day4,
      weekend: day4.getDay(),
    },
    {
      id: "5",
      date: day5,
      weekend: day5.getDay(),
    },
    {
      id: "6",
      date: day6,
      weekend: day6.getDay(),
    },
  ];

  return (
    <>
      <Form
        ref={ref}
        validationSchema={schema}
        onSubmit={() => {}}
        initialValues={{ ...initData, ...data, additionalLines: data?.additionalLines || [], lines: data?.lines || [], attachments: data?.attachments || [] }}>
        {({ values, setFieldValue, errors }) => {
          return (
            <PageLayout
              title="Шинэ захиалга"
              subTitle="Гэрээт харилцагчид хандаж борлуулалтын захиалга бүртгэх"
              breadcrumb={breadcrumbs}
              extra={[
                <Button onClick={() => navigate("/supplier-customer")} key={1} variant="default">
                  Буцах
                </Button>,
                <Button
                  key={2}
                  type="submit"
                  variant="outline"
                  loading={loading}
                  onClick={async () => {
                    let values = await ref.current.submit();
                    if (!!values) onSubmit(values, "save");
                  }}>
                  Хадгалах
                </Button>,
                <Button
                  key={3}
                  variant="light"
                  loading={loading}
                  onClick={async () => {
                    let values = await ref.current.submit();
                    if (!!values) onSubmit(values, "review");
                  }}>
                  Хянуулах
                </Button>,
                <Button
                  key={4}
                  type="submit"
                  loading={loading}
                  onClick={async () => {
                    let values = await ref.current.submit();
                    if (!!values) onSubmit(values, "send");
                  }}>
                  Илгээх
                </Button>,
              ]}>
              <input type="hidden" name="partnerType" value={data?.partnerType} />
              <Box
                sx={(theme) => ({
                  background: theme.white,
                  borderRadius: 5,
                  border: `1px solid ${theme.colors.gray[2]}`,
                  padding: "20px 20px",
                  borderLeft: `2px outset #3BC9DB`,
                })}
                mb={5}>
                <Flex mih={10} gap="md" justify="space-between" align="center" direction="row" wrap="wrap">
                  <Group>
                    <Badge size="md" radius="sm" variant="outline" color="green">
                      {action[1]?.networkStatus}
                    </Badge>
                    <Text size="sm" fw={700} c="cyan">
                      {action[1]?.refCode || "-"}
                    </Text>
                  </Group>
                  <Group>
                    <div>
                      <Group>
                        <Text size="sm" fw={500} c="#44566C">
                          {"бүртгэсэн"}:
                        </Text>
                        <Text size="sm" fw={600} c="#44566C">
                          {user?.lastName !== null ? user?.lastName[0] : "-"}. {user?.firstName !== null ? user?.firstName : "-"}
                        </Text>
                      </Group>
                      <Flex gap={5} wrap="nowrap" justify="flex-end">
                        <Text size="sm" fw={500} c="cyan">
                          {dateTimeSecFormat(nowMoment)}
                        </Text>
                      </Flex>
                    </div>
                  </Group>
                </Flex>
              </Box>
              <Box
                sx={(theme) => ({
                  background: theme.white,
                  borderRadius: 5,
                  border: `1px solid ${theme.colors.gray[2]}`,
                  padding: "20px 20px",
                  borderLeft: `2px outset #3BC9DB`,
                })}
                mb={5}>
                <Flex h="100%" mih={10} direction="row" justify="space-between" gap="md">
                  <Text fw={500}>{"ХАРИЛЦАГЧИЙН МЭДЭЭЛЭЛ"}</Text>
                  <ActionIcon variant="transparent">
                    {!collapseOpen ? (
                      <IconSquareArrowDown color="#3BC9DB" onClick={() => setCollapseOpen(true)} />
                    ) : (
                      <IconSquareArrowUp color="#3BC9DB" onClick={() => setCollapseOpen(false)} />
                    )}
                  </ActionIcon>
                </Flex>
              </Box>
              <Collapse in={collapseOpen}>
                <Box
                  sx={(theme) => ({
                    background: theme.white,
                    borderRadius: 5,
                    border: `1px solid ${theme.colors.gray[2]}`,
                    padding: "20px 20px",
                  })}
                  mb={5}>
                  <Grid>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <TextField
                        name="regNumber"
                        label="Татвар төлөгчийн дугаараар хайх: "
                        placeholder="ТТД:"
                        onChangeCustom={() => {
                          setFieldValue("partnerName", undefined);
                          setFieldValue("supplierId", undefined);
                        }}
                        rightSection={<IconSearch stroke={0.8} style={{ marginRight: 5 }} />}
                        required
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <TextField
                        name="partnerName"
                        label="Партнерийн нэрээр хайх:"
                        placeholder="Нэрээр хайх"
                        onChangeCustom={() => {
                          setFieldValue("regNumber", undefined);
                          setFieldValue("supplierId", undefined);
                        }}
                        rightSection={
                          <IconX
                            size={15}
                            style={{ display: "block", opacity: 0.5 }}
                            onClick={() => {
                              setFieldValue("partnerName", undefined);
                              setFieldValue("supplierId", undefined);
                              setFieldValue("regNumber", undefined);
                            }}
                          />
                        }
                        required
                      />
                    </Grid.Col>

                    {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_NET").length > 0 && (
                      <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                        <SelectField
                          name="supplierId"
                          label="Худалдан авагч бизнес:"
                          placeholder="сонгох"
                          options={(networkData?.res || [])
                            .filter((a: any) => {
                              if (values.regNumber) return a.regNumber === values.regNumber;
                              if (values.partnerName) return a.partnerName.includes(values.partnerName);
                              return true;
                            })
                            .map((item: INetworkSelect) => ({
                              label: item.profileName,
                              value: item.id,
                            }))}
                          onChange={(e: any) => {
                            setFieldValue("regNumber", networkData?.mapedRes[e]?.regNumber);
                            setFieldValue("partnerName", networkData?.mapedRes[e]?.partnerName);
                            setFieldValue("supplierId", e);
                            businessOnChange(e, setFieldValue);
                          }}
                          rightSection={
                            <IconX
                              size={15}
                              style={{ display: "block", opacity: 0.5 }}
                              onClick={() => {
                                setFieldValue("regNumber", undefined);
                                setFieldValue("partnerName", undefined);
                                setFieldValue("supplierId", undefined);
                              }}
                            />
                          }
                          required
                        />
                      </Grid.Col>
                    )}

                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Партнерийн нэр:">
                        {values?.types ? (
                          <Text fw={500} c="cyan">
                            {businessData?.partner
                              ? businessData?.partner?.refCode || businessData?.partner?.businessName
                                ? (businessData?.partner?.refCode, businessData?.partner?.businessName)
                                : "-"
                              : "-"}
                          </Text>
                        ) : (
                          <Text fw={500} c="cyan">
                            {action[1]?.partner?.refCode || action[1]?.partner?.businessName
                              ? [action[1]?.partner?.refCode, " ", action[1]?.partner?.businessName]
                              : "-"}
                          </Text>
                        )}
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Харилцагчийн нэр:">
                        {values?.types ? (
                          <Text fw={500} c="cyan">
                            {businessData?.refCode || "-"}, {businessData?.profileName || "-"}
                          </Text>
                        ) : (
                          <Text fw={500} c="cyan">
                            {action[1]?.refCode || action[1]?.profileName ? [action[1]?.refCode, " ", action[1]?.profileName] : "-"}
                          </Text>
                        )}
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Татвар төлөгчийн дугаар:">
                        {values?.types ? (
                          <Text fw={500} c="cyan">
                            {businessData?.regNumber || "-"}
                          </Text>
                        ) : (
                          <Text fw={500} c="cyan">
                            {action[1]?.regNumber || "-"}
                          </Text>
                        )}
                      </Input.Wrapper>
                    </Grid.Col>

                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Төлбөрийн нөхцөл:">
                        {values?.types ? (
                          <Text fw={500} c="cyan">
                            {businessData?.paymentTerm?.description || "-"}
                          </Text>
                        ) : (
                          <Text fw={500} c="cyan">
                            {action[1]?.paymentTerm?.description || "-"}
                          </Text>
                        )}
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Нийлүүлэгчийн менежер:">
                        {values?.types ? (
                          <Text fw={500} c="cyan">
                            {businessData?.supplierStaff
                              ? businessData?.supplierStaff?.lastName || businessData?.supplierStaff?.firstName
                                ? [businessData?.supplierStaff?.lastName, " ", businessData?.supplierStaff?.firstName]
                                : "-"
                              : "-"}
                          </Text>
                        ) : (
                          <Text fw={500} c="cyan">
                            {action[1]?.supplierStaff
                              ? action[1]?.supplierStaff?.lastName || action[1]?.supplierStaff?.firstName
                                ? [action[1]?.supplierStaff?.lastName, " ", action[1]?.supplierStaff?.firstName]
                                : "-"
                              : "-"}
                          </Text>
                        )}
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Хариуцдаг менежер:">
                        {values?.types ? (
                          <Text fw={500} c="cyan">
                            {businessData?.buyerStaff
                              ? businessData?.buyerStaff?.lastName || businessData?.buyerStaff?.firstName
                                ? [businessData?.buyerStaff?.lastName, " ", businessData?.buyerStaff?.firstName]
                                : "-"
                              : "-"}
                          </Text>
                        ) : (
                          <Text fw={500} c="cyan">
                            {action[1]?.buyerStaff
                              ? action[1]?.buyerStaff?.lastName || action[1]?.buyerStaff?.firstName
                                ? [action[1]?.buyerStaff?.lastName, " ", action[1]?.buyerStaff?.firstName]
                                : "-"
                              : "-"}
                          </Text>
                        )}
                      </Input.Wrapper>
                    </Grid.Col>

                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Хүлээн авах ажилтан:">
                        {values?.types ? (
                          <Text fw={500} c="cyan">
                            {businessData?.receiverStaff
                              ? businessData?.receiverStaff?.lastName || businessData?.receiverStaff?.firstName
                                ? [businessData?.receiverStaff?.lastName, " ", businessData?.receiverStaff?.firstName]
                                : "-"
                              : "-"}
                          </Text>
                        ) : (
                          <Text fw={500} c="cyan">
                            {action[1]?.receiverStaff
                              ? action[1]?.receiverStaff?.lastName || action[1]?.receiverStaff?.firstName
                                ? [action[1]?.receiverStaff?.lastName, " ", action[1]?.receiverStaff?.firstName]
                                : "-"
                              : "-"}
                          </Text>
                        )}
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Хүлээн авах салбар:">
                        {values?.types ? (
                          <Text fw={500} c="cyan">
                            {businessData?.receiverBranch?.name || "-"}
                          </Text>
                        ) : (
                          <Text fw={500} c="cyan">
                            {action[1]?.receiverBranch?.name || "-"}
                          </Text>
                        )}
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <Input.Wrapper label="Хүлээн авах хаяг:">
                        <Group>
                          <ActionIcon variant="transparent">
                            <IconMap2 color="#3BC9DB" />
                          </ActionIcon>
                          {values?.types ? (
                            <Text fw={500} c="cyan">
                              {businessData?.receiverBranch?.branchAddress || "-"}
                            </Text>
                          ) : (
                            <Text fw={500} c="cyan">
                              {action[1]?.receiverBranch?.branchAddress}
                            </Text>
                          )}
                        </Group>
                      </Input.Wrapper>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <RadioGroupField
                        name="deliveryType"
                        label="Захиалгыг хүлээн авах"
                        options={(deliveryDateTypes || [])?.map((item: any) => ({
                          label: item?.name,
                          value: item?.code,
                        }))}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      {values?.deliveryType === "DEFAULT_DATE" ? (
                        <Input.Wrapper label="Хамгийн ойрхон хүргэх боломжтой хуваарьт өдөр:" error={!values?.deliveryCheck && errors?.deliveryCheck}>
                          <Grid mt={5}>
                            {(staticDay || [])?.map((item: any, index: any) => {
                              return (
                                <Grid.Col
                                  span={{ xs: 12, sm: 6, md: 4 }}
                                  key={index}
                                  onClick={() => {
                                    setFieldValue("deliveryCheck", item.date.toString());
                                    setActive(index);
                                  }}>
                                  <UnstyledButton className={cx(classes.item, { [classes.linkActive]: active === index })}>
                                    <Flex justify="center" align="center" direction="column" wrap="nowrap">
                                      <Text size="sm" fw={500} c="#44566C">
                                        {dateFormat(item?.date)}
                                      </Text>
                                      <Text size="sm" fw={500} c="#44566C">
                                        {item?.weekend === 1 && "Даваа"}
                                        {item?.weekend === 2 && "Мягмар"}
                                        {item?.weekend === 3 && "Лхагва"}
                                        {item?.weekend === 4 && "Пүрэв"}
                                        {item?.weekend === 5 && "Баасан"}
                                        {item?.weekend === 6 && "Бямба"}
                                        {item?.weekend === 0 && "Ням"}
                                      </Text>
                                    </Flex>
                                  </UnstyledButton>
                                </Grid.Col>
                              );
                            })}
                          </Grid>
                        </Input.Wrapper>
                      ) : (
                        <DatePickerField name="deliveryDate" placeholder="өдрийг сонгох" label="Хамгийн ойрхон хүргэх боломжтой хуваарьт өдөр:" required />
                      )}
                    </Grid.Col>
                  </Grid>
                </Box>
              </Collapse>
              <Box
                sx={(theme) => ({
                  background: theme.white,
                  borderRadius: 5,
                  border: `1px solid ${theme.colors.gray[2]}`,
                  padding: "20px 20px",
                  borderLeft: `2px outset #3BC9DB`,
                })}
                mb={5}>
                <Flex h="100%" mih={10} direction="row" justify="space-between" gap="md">
                  <Text fw={500}>{"ЗАХИАЛГЫН ЗҮЙЛС"}</Text>
                  <ActionIcon variant="transparent">
                    {!collapsedOpen ? (
                      <IconSquareArrowDown color="#3BC9DB" onClick={() => setCollapsedOpen(true)} />
                    ) : (
                      <IconSquareArrowUp color="#3BC9DB" onClick={() => setCollapsedOpen(false)} />
                    )}
                  </ActionIcon>
                </Flex>
              </Box>

              <Collapse in={collapsedOpen}>
                <SupplierCustomerDynamicForm values={values} setFieldValue={setFieldValue} errors={errors} />
              </Collapse>
            </PageLayout>
          );
        }}
      </Form>
    </>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/supplier-customer",
    label: "Гэрээт харилцагчид",
  },
  {
    label: "Шинэ захиалга",
  },
];

const useStyles = createStyles((theme) => ({
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    border: `1px solid #868E96`,
    borderRadius: theme.radius.md,
    height: 50,
    width: "100%",

    backgroundColor: theme.white,
    transition: "box-shadow 150ms ease, transform 100ms ease",

    "&:hover": {
      boxShadow: theme.shadows.md,
      transform: "scale(1.05)",
    },
  },
  linkActive: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    border: `2px solid #51CF66`,
    borderRadius: theme.radius.md,
    height: 50,
    width: "100%",

    backgroundColor: theme.white,
    transition: "box-shadow 150ms ease, transform 100ms ease",

    "&:hover": {
      boxShadow: theme.shadows.md,
      transform: "scale(1.05)",
    },
  },
}));

import { Table as BaseTable, Center, LoadingOverlay, Pagination, Select, Space, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconChevronDown, IconChevronUp, IconDatabaseOff, IconSelector } from "@tabler/icons-react";
import qs from "qs";
import React, { useImperativeHandle } from "react";
import useSwr from "swr";
import { IFilter } from "../../interfaces/IFilter";

export * from "./row-action";

export type ColumnType<T> = {
  title: string;
  sorter?: boolean;
  dataIndex?: string;
  render: (record: T, index: number) => JSX.Element | string;
  renderCheck?: (record: T, index: number) => JSX.Element | string;
  align?: "left" | "right" | "center";
  width?: string | number;
};

type Props = {
  name: string;
  columns: ColumnType<any>[];
  thead?: JSX.Element;
  filters?: { [key: string]: string | number };
  pagination?: boolean;
  dataSource?: any[];
  loadData?: (filter?: IFilter) => Promise<any>;
  limit?: number;
  onResult?: any;
};

export type ITableRef = { reload: () => void };

export const Table = React.forwardRef(
  ({ name, columns, filters = {}, pagination = true, dataSource = [], loadData, limit = 10, onResult, thead }: Props, ref: React.Ref<ITableRef>) => {
    const { classes } = useStyles();
    const scrollRef = React.useRef<HTMLDivElement>(null);
    const tableRef = React.useRef<HTMLTableElement>(null);
    const [offset, setOffset] = React.useState({ page: 1, limit: limit || 10 });

    const { data, mutate, isLoading } = useSwr(
      `table.${name}.[${offset.page}, ${offset.limit}]?${qs.stringify(filters)}`,
      async () => loadData && (await loadData({ offset, filter: filters } as IFilter)),
      {
        fallbackData: { count: dataSource.length, rows: dataSource },
      },
    );
    const [sorted, setSorting] = React.useState<string[]>([]);

    const onSort = (index?: string) => {
      if (index) setSorting((state) => [index, state[0] === index ? (state[1] === "asc" ? "desc" : "asc") : "asc"]);
    };

    const onScroll = () => {};

    useImperativeHandle(ref, () => ({
      reload() {
        return mutate();
      },
    }));

    return (
      <div className={classes.container}>
        <div ref={scrollRef} onScroll={onScroll} className={classes.scroll}>
          <BaseTable ref={tableRef} horizontalSpacing="md" verticalSpacing="sm" highlightOnHover striped="even">
            <BaseTable.Thead>
              <BaseTable.Tr>
                {columns.map((column, index) => (
                  <Th key={index} column={column} sorted={sorted[0] === column.dataIndex && sorted[1]} onSort={() => onSort(column.dataIndex)}>
                    {column.title}
                    {column.renderCheck && column.renderCheck(data, index)}
                  </Th>
                ))}
              </BaseTable.Tr>
            </BaseTable.Thead>
            <BaseTable.Tbody>
              {thead && thead}
              {data?.rows?.length > 0 && onResult && onResult(data)}
              {data?.rows?.length > 0 ? (
                data?.rows?.map((row: any, index: number) => {
                  return (
                    <BaseTable.Tr key={row.id ?? index}>
                      {columns.map((column, index2) => {
                        return (
                          <BaseTable.Td key={`${index2}`} align={column.align || "left"}>
                            {column.render(row, index)}
                          </BaseTable.Td>
                        );
                      })}
                    </BaseTable.Tr>
                  );
                })
              ) : (
                <BaseTable.Tr key={"Noting"}>
                  <BaseTable.Td colSpan={columns.length}>
                    <Center sx={(theme) => ({ flexDirection: "column", gap: 10, padding: theme.spacing.xl })}>
                      <IconDatabaseOff size={36} stroke={1.5} />
                      <Text fw={500} ta="center">
                        Өгөгдөл олдсонгүй.
                      </Text>
                    </Center>
                  </BaseTable.Td>
                </BaseTable.Tr>
              )}
            </BaseTable.Tbody>
          </BaseTable>

          <Space h="md" />

          <LoadingOverlay visible={isLoading} />
        </div>

        {pagination && (
          <div className={classes.pagination}>
            <Pagination
              total={Math.ceil(data.count / offset.limit)}
              value={offset.page}
              onChange={(page) => setOffset((state) => ({ ...state, page: page }))}
              siblings={1}
              boundaries={1}
              withControls={true}
              disabled={Math.ceil(data.count / offset.limit) <= 1}
              hideWithOnePage
            />
            {data.count > 0 && data && (
              <Select
                onChange={(e: string | null) => {
                  if (e !== null) {
                    setOffset((state) => ({ ...state, limit: parseInt(e, 10), page: 1 }));
                  }
                }}
                value={`${offset.limit}`}
                w={"100px"}
                size="xs"
                defaultValue={"10"}
                data={["5", "10", "20", "30", "50", "100", "200"]}
              />
            )}
          </div>
        )}
      </div>
    );
  },
);

interface ThProps {
  children: React.ReactNode;
  column: ColumnType<any>;
  sorted?: string | unknown;
  onSort(): void;
}

const Th = ({ children, column, sorted, onSort }: ThProps) => {
  const { classes } = useStyles();
  const Icon = sorted ? (sorted === "asc" ? IconChevronUp : IconChevronDown) : IconSelector;

  if (!column.sorter)
    return (
      <BaseTable.Th style={{ width: column.width }}>
        <span style={{ whiteSpace: "nowrap" }}>{children}</span>
      </BaseTable.Th>
    );

  return (
    <BaseTable.Th className={`${classes.control}`} style={{ width: column.width }} onClick={() => onSort()}>
      <div className={classes.button}>
        <span className={classes.child}>{children}</span>
        <span className={classes.icon}>
          <Icon size={14} stroke={1.5} />
        </span>
      </div>
    </BaseTable.Th>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    position: "relative",
    minWidth: "100%",
  },

  scroll: {
    overflowX: "auto",
  },

  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },

  child: {
    display: "flex",
    whiteSpace: "nowrap",
  },

  icon: {
    display: "flex",
  },

  pagination: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: "15px 0",
  },

  control: {
    cursor: "pointer",
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },
}));

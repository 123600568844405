import { ActionIcon, Button, Flex, LoadingOverlay, Paper, Tabs, Text, Tooltip } from "@mantine/core";
import { IconFileText, IconTruckDelivery, IconTruckLoading } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { OrderApi, SplitApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { IAuth } from "../../interfaces/IAuth";
import { IPermissions } from "../../interfaces/IPermissions";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";
import { SupplierSplitList } from "../supplier-split/list";
import { SupplierSalesList } from "./list";

export function SupplierSalesTabs() {
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = React.useState<string>("DELIVERY");
  const [checked, setChecked] = React.useState<string[]>([]);
  const [actions, setActions] = React.useState<string[]>([]);
  const [splitChecked, setSplitChecked] = React.useState<string[]>([]);
  const [action, setAction] = React.useState<string[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const order = async () => {
    setIsLoading(true);
    try {
      if (activeTab === "DELIVERY") {
        let res = await OrderApi.list({
          filter: {
            type: "SALES",
            orderStatus: "",
            businessId: "",
            startDate: "",
            endDate: "",
            invoiceStatus: "",
            paymentStatus: "",
            paymentTermCondition: "",
            excel: `${true}`,
          },
          offset: {
            limit: 20,
            page: 1,
          },
        });

        window.open(res.excelUrl as string, "_blank");
      } else {
        let res = await SplitApi.list({
          filter: {
            orderStatus: "",
            businessId: "",
            startDate: "",
            endDate: "",
            excel: `${true}`,
          },
          offset: {
            limit: 20,
            page: 1,
          },
        });

        window.open(res.excelUrl as string, "_blank");
      }
    } catch (err) {
      message.error((err as HttpHandler)?.message!);
    }
    setIsLoading(false);
  };

  if (isLoading) return <LoadingOverlay visible={isLoading} />;

  return (
    <PageLayout
      title="Борлуулалтын захиалгын жагсаалт"
      subTitle="Харилцагч байгууллагуудад хүргэх борлуулалтын захиалга"
      breadcrumb={breadcrumbs}
      extra={[
        <Flex key={1} gap="md" align="center">
          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_DN_ASSIGN").length > 0 && (
            <>
              {activeTab === "DELIVERY" && (
                <Button
                  onClick={() => setActions(["delivery", checked as any])}
                  leftSection={<IconTruckDelivery />}
                  disabled={checked?.length === 0}
                  variant="outline">
                  Хүргэлт үүсгэх
                </Button>
              )}
            </>
          )}
          {activeTab === "SPLIT" && (
            <Button
              onClick={() => setAction(["split", splitChecked as any])}
              leftSection={<IconTruckDelivery />}
              disabled={splitChecked?.length === 0}
              variant="outline">
              Хуваарилах
            </Button>
          )}

          {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isCreate).length > 0 && (
            <>
              {activeTab === "DELIVERY" && (
                <Button
                  onClick={() => {
                    navigate("/supplier-sales/new");
                  }}
                  size="sm">
                  Шинэ захиалга
                </Button>
              )}
            </>
          )}
          <Tooltip label="Файл" color="dark" position="bottom" withArrow={false} onClick={() => order()}>
            <ActionIcon variant="outline" size="lg">
              <IconFileText />
            </ActionIcon>
          </Tooltip>
        </Flex>,
      ]}>
      <Paper radius="sm" p="md" withBorder>
        <Tabs defaultValue="1" value={activeTab} onChange={(e) => setActiveTab(e as string)}>
          <Tabs.List>
            {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isView).length > 0 && (
              <Tabs.Tab leftSection={<IconTruckLoading color="#44566C" />} value="DELIVERY">
                <Text size="sm" fw={500} c="#44566C">
                  Нийт захиалга
                </Text>
              </Tabs.Tab>
            )}

            {(user?.permissions || []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_SPLIT").length > 0 && (
              <Tabs.Tab leftSection={<IconTruckDelivery color="#44566C" />} value="SPLIT">
                <Text size="sm" fw={500} c="#44566C">
                  Хэсэгчлэн хүргэх
                </Text>
              </Tabs.Tab>
            )}
          </Tabs.List>
          {(user?.permissions ?? []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_LIST" && c.isView).length > 0 && (
            <Tabs.Panel value="DELIVERY" mt="lg">
              <SupplierSalesList setChecked={setChecked} actions={actions} checked={checked} setActions={setActions} />
            </Tabs.Panel>
          )}
          {(user?.permissions ?? []).filter((c: IPermissions) => c.module === "ORDER" && c.code === "ORD_SPLIT").length > 0 && (
            <Tabs.Panel value="SPLIT" mt="lg">
              <SupplierSplitList
                setSplitChecked={setSplitChecked}
                action={action}
                splitChecked={splitChecked}
                setAction={setAction}
                success={() => setActiveTab("SPLIT")}
              />
            </Tabs.Panel>
          )}
        </Tabs>
      </Paper>
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Борлуулалтын захиалгын жагсаалт",
  },
];

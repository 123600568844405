import { Box, Button, CloseButton, Divider, Flex, Grid, Group, Image, LoadingOverlay, Modal, NumberInput, Text, Textarea } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconCreditCard } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useSwr from "swr";
import * as yup from "yup";
import { InvoiceApi, OrderApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { ILine } from "../../interfaces/ILine";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { dateTimeSecFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";
import { Form } from "../form";
import { SelectField } from "../form/select-field";
import { FormLayout } from "../layout";
import { ColumnType, Table } from "../table";

const schema = yup.object({
  method: yup.string().required("Заавал бөглөнө!").nullable(),
});

export type IFormData = {
  name: string;
  amount: string;
  addInfo: string;
  method: string;
};

type Props = {
  action: any;
  setAction: any;
  reload: any;
};

export function BuyerSalesPaymentForm({ action, setAction, reload }: Props) {
  const { classes } = useStyles();
  const [opened, setOpened] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [qrCode, setQrCode] = React.useState<any>();
  const { paymentMethod, bankAccounts } = useSelector((state: { general: IGeneral }) => state.general);
  const [invoiceData, setInvoiceData] = useState(Object.create({}));

  const [data] = React.useState<IFormData>({
    name: "",
    amount: "",
    addInfo: "",
    method: "",
  });

  useEffect(() => {
    const fetchInvoiceData = async () => {
      if (!action || !action[1]?.proformaInvoiceId) return;

      setLoading(true);

      try {
        const data = await InvoiceApi.get(action[1].proformaInvoiceId);
        setInvoiceData(data);
      } catch (err) {
        message.error((err as HttpHandler)?.message!);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoiceData();
  }, [action]);
  const { data: orderData } = useSwr(`/inv/invoice/order`, async () => await OrderApi.get(action && action[1]?.id));

  const columns = useHeader({
    onClick: (key, record) => {},
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    if (data?.method === "QPAY") {
      try {
        let res = await InvoiceApi.pay({
          invoiceId: invoiceData?.id,
          invoiceRefCode: invoiceData?.refCode,
          receiverBusinessId: invoiceData?.senderBusiness?.id,
          method: data.method,
          amount: invoiceData?.amountToPay,
          description: invoiceData?.refCode,
          creditAccountId: invoiceData?.receiverAcc?.id,
          creditAccountBank: invoiceData?.receiverAcc?.bankName,
          creditAccountName: invoiceData?.receiverAcc?.name,
          creditAccountNumber: invoiceData?.receiverAcc?.number,
          creditAccountCurrency: invoiceData?.receiverAcc?.currency,
          debitAccountId: invoiceData?.senderAcc?.id,
          debitAccountBank: invoiceData?.senderAcc?.bankName,
          debitAccountName: invoiceData?.senderAcc?.name,
          debitAccountNumber: invoiceData?.senderAcc?.number,
          debitAccountCurrency: invoiceData?.senderAcc?.currency,
          addInfo: "",
          files: [],
        });

        setOpened(["image"]);
        setQrCode(res?.qr_image);
        message.success("Таны хүсэлт амжилттай");
      } catch (err) {
        message.error((err as HttpHandler)?.message!);
      }
    } else {
      try {
        let res = await InvoiceApi.pay({
          invoiceId: invoiceData?.id,
          invoiceRefCode: invoiceData?.refCode,
          receiverBusinessId: invoiceData?.senderBusiness?.id,
          method: data.method,
          amount: invoiceData?.amountToPay,
          description: invoiceData?.refCode,
          creditAccountId: invoiceData?.receiverAcc?.id,
          creditAccountBank: invoiceData?.receiverAcc?.bankName,
          creditAccountName: invoiceData?.receiverAcc?.name,
          creditAccountNumber: invoiceData?.receiverAcc?.number,
          creditAccountCurrency: invoiceData?.receiverAcc?.currency,
          debitAccountId: invoiceData?.senderAcc?.id,
          debitAccountBank: invoiceData?.senderAcc?.bankName,
          debitAccountName: invoiceData?.senderAcc?.name,
          debitAccountNumber: invoiceData?.senderAcc?.number,
          debitAccountCurrency: invoiceData?.senderAcc?.currency,
          addInfo: "",
          files: [],
        });
        setAction([]);
        reload();
        window.open(res?.url);
        message.success("Таны хүсэлт амжилттай");
      } catch (err) {
        message.error((err as HttpHandler)?.message!);
      }
    }

    setLoading(false);
  };

  const onCancel = async () => {
    setAction([]);
    reload();
    setOpened([]);
  };

  return (
    <>
      <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
        {({ values }) => {
          return (
            <FormLayout title={"Төлбөр баталгаажуулах"} subTitle={""} my={20} extra={[<CloseButton key="cancel" onClick={() => setAction([])} />]}>
              <LoadingOverlay visible={loading} />
              <Divider color="gray" my="xs" />
              <Grid>
                <Grid.Col span={{ xs: 12, sm: 6, lg: 9 }}>
                  <Box
                    sx={(theme) => ({
                      background: theme.white,
                      borderRadius: 1,
                      border: `1px solid ${theme.colors.gray[2]}`,
                      height: "100%",
                    })}
                    mb={5}>
                    <Grid mr={10} ml={10}>
                      <Grid.Col span={1}>
                        <div
                          style={{
                            marginLeft: "-122px",
                            backgroundColor: "#49b5b9",
                            width: "298px",
                            height: "50px",
                            transformOrigin: "center",
                            marginRight: 2,
                            transform: `rotate(-90deg) translate(-42%, 0)`,
                          }}>
                          <div
                            style={{
                              textAlign: "center",
                              color: "white",
                              fontSize: 24,
                              paddingTop: 5,
                            }}>
                            Төлбөр хүлээж буй
                          </div>
                        </div>
                      </Grid.Col>
                      <Grid.Col span={8} className={classes.height}>
                        <Flex mih={50} gap="md" justify="flex-start" align="flex-start" direction="column" wrap="wrap" w={300} mt={80}>
                          <Grid>
                            <Grid.Col span={12}>
                              <Group align="apart">
                                <Text fw={500} c="dark">
                                  Нэхэмжлэх №:
                                </Text>{" "}
                                <Text fw={500} c="#49b5b9">
                                  {invoiceData?.refCode || "-"}
                                </Text>
                              </Group>
                            </Grid.Col>
                            <Grid.Col span={12}>
                              <Group align="apart">
                                <Text fw={500} c="dark">
                                  Захиалга №:
                                </Text>{" "}
                                <Text fw={500} c="#49b5b9">
                                  {invoiceData?.respondText || "-"}
                                </Text>
                              </Group>
                            </Grid.Col>
                            <Grid.Col span={12}>
                              <Group align="apart">
                                <Text fw={500} c="dark">
                                  Худалдан авагч:
                                </Text>{" "}
                                <Text fw={500} c="#49b5b9">
                                  {invoiceData?.receiverBusiness?.profileName || "-"}
                                </Text>
                              </Group>
                            </Grid.Col>
                            <Grid.Col span={{ xs: 12, sm: 6, lg: 12 }}>
                              <Group align="apart">
                                <Text fw={500} c="dark">
                                  Нийлүүлэгч:
                                </Text>{" "}
                                <Text fw={500} c="#49b5b9">
                                  {invoiceData?.senderBusiness?.profileName || "-"}
                                </Text>
                              </Group>
                            </Grid.Col>
                          </Grid>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col span={3} className={classes.height}>
                        <Group align="right" mr={10} mt={40}>
                          <Text fw={500} c="dark">
                            {"Сангийн сайдын 2017 оны 347 дугаар Тушаал хавсралт №"}
                          </Text>
                        </Group>
                      </Grid.Col>
                    </Grid>
                    <Grid mr={10} ml={10} mt={10}>
                      <Grid.Col span={{ xs: 12, sm: 6, lg: 12 }}>
                        <Flex gap="md" justify="center" align="center" direction="row" wrap="wrap">
                          <Text fw={600} c="dark">
                            {"НЭХЭМЖЛЭХ"}
                          </Text>
                        </Flex>
                      </Grid.Col>

                      <Grid.Col span={{ xs: 12, sm: 6, lg: 12 }}>
                        <Text fw={500} c="dimmed">
                          {"ДДТД: Тун удахгүй"}
                        </Text>
                      </Grid.Col>

                      <Grid.Col span={{ xs: 12, sm: 6, lg: 6 }}>
                        <Text fw={500} c="dark">
                          {"Нэхэмжлэх"}
                        </Text>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 12, sm: 6, lg: 6 }}>
                        <Text fw={500} c="dark">
                          {"Хариуцагч"}
                        </Text>
                      </Grid.Col>

                      <Grid.Col span={{ xs: 12, sm: 6, lg: 6 }}>
                        <Group ml="xs">
                          <Text fw={500} c="dark">
                            {"ТТД:"}
                          </Text>
                          <Text fw={500} c="dimmed">
                            {invoiceData?.senderBusiness?.regNumber || "-"}
                          </Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 12, sm: 6, lg: 6 }}>
                        <Group ml="xs">
                          <Text fw={500} c="dark">
                            {"ТТД:"}
                          </Text>
                          <Text fw={500} c="dimmed">
                            {invoiceData?.receiverBusiness?.regNumber || "-"}
                          </Text>
                        </Group>
                      </Grid.Col>

                      <Grid.Col span={{ xs: 12, sm: 6, lg: 6 }}>
                        <Group ml="xs">
                          <Text fw={500} c="dark">
                            {"Нэр:"}
                          </Text>
                          <Text fw={500} c="dimmed">
                            {invoiceData?.senderBusiness?.partner?.businessName || "-"}
                          </Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 12, sm: 6, lg: 6 }}>
                        <Group ml="xs">
                          <Text fw={500} c="dark">
                            {"Нэр:"}
                          </Text>
                          <Text fw={500} c="dimmed">
                            {invoiceData?.receiverBusiness?.partner?.businessName || "-"}
                          </Text>
                        </Group>
                      </Grid.Col>

                      <Grid.Col span={{ xs: 12, sm: 6, lg: 6 }}>
                        <Group ml="xs">
                          <Text fw={500} c="dark">
                            {"Хаяг:"}
                          </Text>
                          <Text fw={500} c="dimmed">
                            {"N/A"}
                          </Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 12, sm: 6, lg: 6 }}>
                        <Group ml="xs">
                          <Text fw={500} c="dark">
                            {"Хаяг:"}
                          </Text>
                          <Text fw={500} c="dimmed">
                            {"N/A"}
                          </Text>
                        </Group>
                      </Grid.Col>

                      <Grid.Col span={{ xs: 12, sm: 6, lg: 6 }}>
                        <Group ml="xs">
                          <Text fw={500} c="dark">
                            {"Утас:"}
                          </Text>
                          <Text fw={500} c="dimmed">
                            {invoiceData?.senderBusiness?.partner?.phone || "-"}
                          </Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 12, sm: 6, lg: 6 }}>
                        <Group ml="xs">
                          <Text fw={500} c="dark">
                            {"Утас:"}
                          </Text>
                          <Text fw={500} c="dimmed">
                            {invoiceData?.receiverBusiness?.partner?.phone || "-"}
                          </Text>
                        </Group>
                      </Grid.Col>

                      <Grid.Col span={{ xs: 12, sm: 6, lg: 6 }}>
                        <Group ml="xs">
                          <Text fw={500} c="dark">
                            {"Э-шуудан:"}
                          </Text>
                          <Text fw={500} c="dimmed">
                            {invoiceData?.senderBusiness?.partner?.email || "-"}
                          </Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 12, sm: 6, lg: 6 }}>
                        <Group ml="xs">
                          <Text fw={500} c="dark">
                            {"Гэрээний №:"}
                          </Text>
                          <Text fw={500} c="dimmed">
                            {"Одоогоор хоосон"}
                          </Text>
                        </Group>
                      </Grid.Col>

                      <Grid.Col span={{ xs: 12, sm: 6, lg: 6 }}>
                        <Group ml="xs">
                          <Text fw={500} c="dark">
                            {"Банкны нэр:"}
                          </Text>
                          <Text fw={500} c="dimmed">
                            {invoiceData?.senderAcc?.bankName || "-"}
                          </Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 12, sm: 6, lg: 6 }}>
                        <Group ml="xs">
                          <Text fw={500} c="dark">
                            {"Огноо:"}
                          </Text>
                          <Text fw={500} c="dimmed">
                            {dateTimeSecFormat(invoiceData?.createdAt) || "-"}
                          </Text>
                        </Group>
                      </Grid.Col>

                      <Grid.Col span={{ xs: 12, sm: 6, lg: 6 }}>
                        <Group ml="xs">
                          <Text fw={500} c="dark">
                            {"Банкны дансны дугаар:"}
                          </Text>
                          <Text fw={500} c="dimmed">
                            {invoiceData?.senderAcc?.number || "-"}
                          </Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 12, sm: 6, lg: 6 }}>
                        <Group ml="xs">
                          <Text fw={500} c="dark">
                            {"Төлбөр хийх хугацаа:"}
                          </Text>
                          <Text fw={500} c="dimmed">
                            {dateTimeSecFormat(invoiceData?.paymentDate) || "-"}
                          </Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={{ xs: 12, sm: 6, lg: 12 }}>
                        <Divider color="#49b5b9" my="xs" />
                        <Table name="supplier-command-receive-test.list" columns={columns} dataSource={orderData?.lines} pagination={false} />
                        <Divider color="#49b5b9" my="xs" />
                      </Grid.Col>
                      <Grid.Col span={{ xs: 12, sm: 6, lg: 12 }}>
                        <Group align="right" mr={10} mt={10}>
                          <Box w={"500px"} c="gray.7">
                            <Grid gutter={"xs"}>
                              <Grid.Col span={6}>
                                <Text fw={500} c="dimmed">
                                  {"Бараа ажил, үйлчилгээний үнэ:"}
                                </Text>
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <Text fw={500} c="dimmed" ta="end">
                                  {currencyFormat(orderData?.orderAmount || 0)}
                                </Text>
                              </Grid.Col>

                              <Grid.Col span={6}>
                                <Text fw={500} c="dimmed">
                                  {"Нэмэгдсэн өртгийн албан татвар:"}
                                </Text>
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <Text fw={500} c="dimmed" ta="end">
                                  {currencyFormat(orderData?.lineVatAmount || 0)}
                                </Text>
                              </Grid.Col>

                              <Grid.Col span={6}>
                                <Text fw={500} c="dimmed">
                                  {"Нийслэл хотын албан татвар:"}
                                </Text>
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <Text fw={500} c="dimmed" ta="end">
                                  {currencyFormat(orderData?.lineTaxAmount || 0)}
                                </Text>
                              </Grid.Col>

                              <Grid.Col span={6}>
                                <Text fw={600} c="dark">
                                  {"НИЙТ ҮНЭ:"}
                                </Text>
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <Text fw={500} c="dimmed" ta="end">
                                  {currencyFormat(orderData?.totalAmount || 0)}
                                </Text>
                              </Grid.Col>

                              <Grid.Col span={12}>
                                <Text fw={600} c="#49b5b9" ta="left">
                                  {"ЗАХИАЛГА БАТАЛГААЖУУЛАХ"}
                                </Text>
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <Text fw={500} c="dimmed">
                                  {"Урьдчилгаа төлбөрийн дүн:"}
                                </Text>
                              </Grid.Col>
                              <Grid.Col span={6}>
                                <Text fw={500} c="dimmed" ta="end">
                                  {currencyFormat(invoiceData?.amountToPay || 0)}
                                </Text>
                              </Grid.Col>

                              <Grid.Col span={12}>
                                <Text fw={500} c="dimmed" ta="left">
                                  {"Төлбөр зохих огноо цаг, Баталгаажуулах огноо цаг"}
                                </Text>
                              </Grid.Col>
                            </Grid>
                          </Box>
                        </Group>
                      </Grid.Col>

                      <Grid.Col span={{ xs: 12, sm: 6, lg: 12 }}>
                        <Group align="left" mt={20} mb={20}>
                          <Box w={"600px"} c="gray.7">
                            <Grid gutter={"xs"}>
                              <Grid.Col span={12}>
                                <Text fw={600} c="#49b5b9">
                                  {"ТЭМДЭГЛЭЛ"}
                                </Text>
                              </Grid.Col>

                              <Grid.Col span={12}>
                                <Text fw={500} c="dimmed">
                                  {"Төлбөр баталгаажуулах огноо цагаас өмнө төлбөр төлөгдөөгүй бол захиалга автоматаар цуцлагдахыг анхаарна уу."}
                                </Text>
                              </Grid.Col>

                              <Grid.Col span={12}>
                                <Text fw={500} c="dimmed">
                                  {"Дансаар төлөх бол гүйлгээний утгад НЭХЭМЖЛЭХ ДУГААР-г бичнэ үү."}
                                </Text>
                              </Grid.Col>
                            </Grid>
                          </Box>
                        </Group>
                      </Grid.Col>
                    </Grid>
                  </Box>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, lg: 3 }}>
                  <Grid.Col span={{ xs: 12, sm: 6, lg: 12 }}>
                    <Text fw={600} c="dark">
                      {"ЗАХИАЛГЫН ТӨЛБӨР БАТАЛГААЖУУЛАХ"}
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, lg: 12 }}>
                    <SelectField
                      name="method"
                      label="Төлбөрийн хэлбэр сонгох"
                      options={(paymentMethod || [])
                        .filter((c: IReference) => c.code !== "CASH" && c.code !== "BANK_CARD" && c.code !== "SOCIAL_PAY" && c.code !== "BANK_ACCOUNT")
                        .map((item: IReference) => {
                          return {
                            label: item.name,
                            value: item.code,
                          };
                        })}
                      placeholder="Бизнес тооцооны данснаас"
                      required
                    />
                  </Grid.Col>
                  {values.method === "B2B" && (
                    <Grid.Col span={{ xs: 12, sm: 6, lg: 12 }}>
                      <SelectField
                        name="name"
                        label="Төлбөр төлөх данс сонгох"
                        options={(bankAccounts || []).map((item: any) => {
                          return {
                            label: `${item.bankName}/${item.number}`,
                            value: item.id,
                            disabled: !item.isEnabled,
                          };
                        })}
                        placeholder="Бизнес тооцооны данснаас"
                        required
                        disabled={values.method === "QPAY"}
                      />
                    </Grid.Col>
                  )}
                  <Grid.Col span={{ xs: 12, sm: 6, lg: 12 }}>
                    <NumberInput label="Төлвөл зохих дүн" value={invoiceData?.amountToPay || 0} />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, lg: 12 }}>
                    <Textarea label="Гүйлгээний утга" value={invoiceData?.refCode} disabled={values.method === "QPAY"} />
                  </Grid.Col>
                  <Grid.Col span={{ xs: 12, sm: 6, lg: 12 }}>
                    <Button type="submit" leftSection={<IconCreditCard />} disabled={invoiceData?.invoiceStatus !== "CONFIRMED"}>
                      Төлбөр батлах
                    </Button>
                  </Grid.Col>
                </Grid.Col>
              </Grid>
            </FormLayout>
          );
        }}
      </Form>
      <Modal opened={opened[0] === "image"} onClose={() => onCancel && onCancel()} withCloseButton={false} centered>
        <Image src={"data:image/png;base64," + qrCode} />
      </Modal>
    </>
  );
}

type IProps = {
  onClick: (key: string, record: ILine) => void;
};

const useHeader = ({ onClick }: IProps): ColumnType<ILine>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "60px",
  },
  {
    title: "Бараа, ажил үйлчилгээний нэр",
    sorter: true,
    dataIndex: "reg_number",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Код",
    sorter: true,
    dataIndex: "reg_number",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.skuCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хэмжих нэгж",
    sorter: true,
    dataIndex: "reg_number",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.unit || "-"}
        </Text>
      );
    },
  },
  {
    title: "Тоо хэмжээ",
    sorter: true,
    dataIndex: "reg_number",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.quantity || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэгжийн үнэ",
    sorter: true,
    dataIndex: "reg_number",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {currencyFormat(record?.price || 0)}
        </Text>
      );
    },
  },
  {
    title: "Бүгд үнэ",
    sorter: true,
    dataIndex: "reg_number",
    render: (record) => {
      let count;
      count = (record?.price || 0) * (record?.quantity || 0);
      return (
        <Text size="sm" fw={500} c="#44566C">
          {currencyFormat(count)}
        </Text>
      );
    },
  },
];

const useStyles = createStyles(() => ({
  wrapper: {
    transform: `rotate(-90deg)`,
  },
  height: {
    height: 300,
  },
}));
